import React, { useState,useEffect} from 'react'
import '../../App.css'
import './Home.css'
import SliderBanner from '../../components/SliderBanner/SliderBanner'
import SliderCar from '../../components/SliderCar/SliderCar'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { instagram_url, youtube_url, tokopedia_url } from '../../assets/config/url'
import ImageShowLocation from '../../assets/images/icon-location-circle.png'
import ImageNeedHelp from '../../assets/images/icon-whatsapp-circle.png'
import ImageYoutube from '../../assets/images/youtube.png'
import ImageInstagram from '../../assets/images/instagram.png'
import ImageTokopedia from '../../assets/images/tokopedia.png'
import IconClose from '../../assets/images/icon-close.png'
import IconWhatsappCircle from '../../assets/images/icon-whatsapp-circle.png'
import IconGooglePlay from '../../assets/images/icon-google-play-clear.png'
import IconAppStore from '../../assets/images/icon-app-store-clear.png'
import IconArrow from '../../assets/images/icon-arrow.png'
import ImageScroll from '../../assets/images/scroll.png'
import { APIGET } from "../../helper/api";
import { BASE_URL, BRAND_URL, FACEBOOK_URL, INSTAGRAM_URL, TOKOPEDIA_URL, MAPS_URL, WHATSAPP_URL, GOOGLE_URL, APPLE_URL, ACCESS_TOKEN } from '../../assets/config/api'

import "aos/dist/aos.css";
import SliderOurHistory from '../../components/SliderOurHistory/SliderOurHistory'
import SliderOurHistoryMobile from '../../components/SliderOurHistoryMobile/SliderOurHistoryMobile'

import { Helmet } from 'react-helmet';

export default function Home() {
  const [lgShow, setLgShow] = useState(false)
  const [mobileShow, setMobileShow] = useState(false)
  const [responseBrand, setResponse] = useState([])
  let param_brand = {
      token: ACCESS_TOKEN,
  }
  const [expand, setExpand] = useState(true)

  useEffect(() => {
    const fetchDataBrand = async () => {
        const res = await APIGET(BRAND_URL, param_brand)
        setResponse(res)
    }
    fetchDataBrand()
  }, [])

  return (
    <div id="body-home">
      <Helmet>
        <title>TDA Luxury Toys</title>
      </Helmet>
      <div className="blink_me_div" >
        <p className="blink_me">TDA LUXURY TOYS</p>
      </div>
      <Container fluid id="our-history-mobile" className={mobileShow ? "" : "display-none"}>
        <Row>
          <Col xs={12} className="our-history-mobile-close-container">
            <img src={IconClose} 
            alt="x"
            onClick={() => setMobileShow(false)}
            className="our-history-mobile-close"
            />
          </Col>
          <Col xs={12}>
            <SliderOurHistoryMobile/>
          </Col>
        </Row>
      </Container>
      
      <Container fluid className="slider-banner-container">
        <SliderBanner />
        <div className={`slider-banner-info ${expand ? 'open' : 'close'} row`}>
          <div className="show-room col-xs-5">
            <div className={`expand-arrow ${expand ? 'open' : 'close'}`} onClick={()=> setExpand(!expand)}>
              <img className="icon-black" src={IconArrow} alt="l"></img>
            </div>
            <a rel="noopener noreferrer" href={MAPS_URL} target="_blank">
              <img src={ImageShowLocation} alt="location" style={{width : "26px"}}/>
              <p>Showroom<br/>Location</p>
            </a>
          </div>
          <div className="need-help col-xs-5">
            <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
              <img src={ImageNeedHelp} alt="Any Inquiry?" style={{width : "25px"}}/>
              <p>Any<br/>Inquiry?</p>
            </a>
          </div>
          <div className="media-social col-xs-2 px-0 align-items-center d-md-flex flex-md-column justify-content-md-center align-items-md-start">
            <div className='icon'>
              <a rel="noopener noreferrer" href={youtube_url} target="_blank">
                <img className="home-footer-credit-policy-img" src={ImageYoutube} alt="youtube" style={{width: "32px"}}/>
              </a>
              <a rel="noopener noreferrer" href={instagram_url} target="_blank">
                <img className="home-footer-credit-policy-img" src={ImageInstagram} alt="instagram"/>
              </a>
              <a rel="noopener noreferrer" href={tokopedia_url} target="_blank">
                <img className="home-footer-credit-policy-img" src={ImageTokopedia} alt="tokopedia" style={{width: "27px"}}/>
              </a>
            </div>
            <p>Connect With<br/>TDA</p>
          </div>
        </div>
      </Container>
        
      <Container fluid className='home-description-container'>
        <Container>
          <Row className='top'>
            <Col className='left home-description-title' lg={5} xs={12} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000">
              <p>Initiated in 2009, TDA LUXURY <br /> 
              TOYS is an automotive <br /> 
              dealership that focuses on <br /> 
              growing the luxury market.</p>
            </Col>
            <Col lg={1} xs={0}></Col>
            <Col className='right home-description-paragraph' lg={6} xs={12}>
              <p data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1300" >TDA offers the best investment and ownership experience with  the most efficient operations. With a strong value in teamwork and a well-maintained community, TDA builds and embeds the passion for luxury automotive within our practice. Our goal is to serve people with unique & passionate thrill-seekers, which the main thing TDA can provide.</p>
              <div  data-aos="fade-up" data-aos-offset="200" data-aos-anchor="#home-desc-trigger" data-aos-easing="ease-in-sine" data-aos-duration="1600">
                <button
                className={"home-description-button button-rectangle our-history-button-mobile"}
                onClick={() => setMobileShow(true)}
                >
                    <p>OUR HISTORY</p>
                </button>
              </div>
              <div  data-aos="fade-up" data-aos-offset="200"  data-aos-anchor="#home-desc-trigger" data-aos-easing="ease-in-sine" data-aos-duration="1600">
                <button
                className={"home-description-button button-rectangle our-history-button-desktop"}
                onClick={() => setLgShow(true)}
                >
                    <p>OUR HISTORY</p>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="container-fluid-brand-card">
        <Container className="container-brand-card">
          <Row className='bottom justify-content-center' data-aos="fade-up" data-aos-anchor="#home-desc-trigger" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1600" >
              {
                responseBrand ? 
                responseBrand.map((brand, index) => {
                  return(
                    <Col className='brand-card' xs={4} lg={2} key={index}>
                      <a rel="noopener noreferrer" href={"/brand/"+brand.title}>
                        <div>
                          <img src={BASE_URL+brand.logo} alt={brand.title}/>
                        </div>
                      </a>
                    </Col>
                  )
                })
                :
                ""
            }
          </Row>
        </Container>
      </Container>

      <Container className="home-slider-container">
        <SliderCar title="New Cars"/>
        <SliderCar title="Used Cars"/>
      </Container>

      <Container fluid className="service-center-background-bottom home-footer">
            <Row>
                <Col lg={5} xs={12} className="home-maps-col">
                    <Row>
                        <Col lg={12} xs={12} className="service-center-maps home-map">
                            <div>
                              <iframe 
                              title="TDA LUXURY TOYS"
                              id="iframehome"
                              width="100%"
                              height="100%"
                              src={`https://maps.google.com/maps?q=-6.2493547,106.7927029&hl=es;&output=embed`}
                              >
                              </iframe>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={7} xs={12} className="home-description-detail-footer">
                  <Container className="home-footer-black">
                    <Row>
                      <Col lg={7} xs={12} className="service-center-address home-description-footer-middle">
                          <h2 className="text-white">
                            TDA LUXURY TOYS
                          </h2>
                          <p className="text-white">
                            Jl. Kramat Pela Raya No.31, RT.1/RW.4, Kramat Pela,<br/>
                            Kec. Kby. Baru, Kota Jakarta Selatan,<br />
                            Daerah Khusus Ibukota Jakarta 12130
                          </p>
                          <br/>
                          <h3 className="text-white mt-1">
                              Opening Hours
                          </h3>
                          <p className="text-white">
                            Mon - Fri : 9.00 AM - 07:30 PM<br/>
                            Sat - Sun : 9.00 AM - 06.00 PM
                          </p>
                          <br/>
                          <h2 className="text-white pt-0 mt-2">
                          TDA PLAZA INDONESIA
                          </h2>
                          <p className="text-white">
                          Jl. MH Thamrin Kav 28-30, Jakarta 10350
                          </p>
                          <br/>
                          <h3 className="text-white mt-1">
                              Opening Hours
                          </h3>
                          <p className="text-white">
                            Sat - Sun : 10.00 AM - 10.00 PM
                          </p>
                          <h3 className="text-white">
                              Email
                          </h3>
                          <p className="text-white">
                              digital@tda.co.id
                          </p>
                          <p className="text-white home-telp mt-1">
                              <span className='home-telp-header'>Telp</span> (021-2277-3115)
                          </p>
                          <h3 className="text-white">
                              Contact Us Now
                          </h3>
                          <div className="service-center-button-whatsapp home-button-whatsapp border-white">
                              <a rel="noopener noreferrer" href={WHATSAPP_URL}>
                                <img src={IconWhatsappCircle} alt="whatsapp" style={{width : "25px", height: "25px"}}/>
                                <p className="text-white">
                                +6281210912222
                                </p>
                              </a>
                          </div>
                      </Col>
                      <Col lg={5} xs={12} className="service-center-address home-footer-apps">
                        <div>
                          <a rel="noopener noreferrer" href={GOOGLE_URL} target="_blank">
                            <img src={IconGooglePlay} className="home-footer-image-apps" alt="google play"/>
                          </a>
                        </div>
                        <div>
                          <a rel="noopener noreferrer" href={APPLE_URL} target="_blank">
                            <img src={IconAppStore} className="home-footer-image-apps" alt="app store"/>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col lg={12} xs={12}>
                  <Container fluid className="home-footer-credit-container">
                    <Row className='px-5'>
                        <Col lg={6} xs={12} className="home-footer-credit">
                            <p>&copy; PT TOP CARS GALLERY</p>
                        </Col>
                        <Col lg={6} xs={12} className="home-footer-credit home-footer-credit-policy">
                            <p>
                              Privacy Policy 
                            </p>
                            <div className='pe-md-5 me-md-5'>
                              <a rel="noopener noreferrer" href={youtube_url} target="_blank">
                                <img className="home-footer-credit-policy-img" src={ImageYoutube} alt="youtube" style={{width: "33px"}}/>
                              </a>
                              <a rel="noopener noreferrer" href={instagram_url} target="_blank">
                                <img className="home-footer-credit-policy-img" src={ImageInstagram} alt="instagram" style={{width: "30px"}}/>
                              </a>
                              <a rel="noopener noreferrer" href={tokopedia_url} target="_blank">
                                <img className="home-footer-credit-policy-img" src={ImageTokopedia} alt="tokopedia" style={{width: "27px"}}/>
                              </a>
                            </div>
                        </Col>
                    </Row>
                  </Container>
                </Col>
            </Row>
        </Container>

        <Modal
          id="modal-our-history"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Body>
            <Container fluid>
              <SliderOurHistory/>
            </Container>
          </Modal.Body>
        </Modal>
    </div>
  );
}
