import React, { useState, useEffect } from 'react'
import './BrandPage.css'
import Slider from "react-slick";
import ImageBanner from '../../assets/images/brand-detail-page-banner-desktop.png'
import { Container, Row, Col } from 'react-bootstrap'
import IconArrowRight from '../../assets/images/right-arrow.png'

import { useParams } from 'react-router-dom';
import { APIGET } from "../../helper/api";
import { BRAND_URL, BASE_URL, ACCESS_TOKEN } from "../../assets/config/api";
import { Helmet } from 'react-helmet';

function SampleNextArrow(props) {
    const { onClick } = props
    return (
        <div
        className="slider-banner-page-next slider-banner-page-arrow"
        onClick={onClick}
        >
        <img src={IconArrowRight} alt="a" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props
    return (
        <div
        className="slider-banner-page-prev slider-banner-page-arrow"
        onClick={onClick}
        >
        <img src={IconArrowRight} alt="a" />
        </div>
    );
}

export default function BrandPage() {
    const params = useParams()
    const [response, setResponse] = useState([])
    const [imageslider1, setImageSlider1] = useState([])
    const [imageslider2, setImageSlider2] = useState([])
    const [imageslider3, setImageSlider3] = useState([])
    const [imageslider4, setImageSlider4] = useState([])

    let param = {
        token: ACCESS_TOKEN,
        title: params.id,
    }
    
    
    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(BRAND_URL, param)
            setResponse(res[0])
            setImageSlider1([
                res[0].section1_image_collection,
                res[0].section1_image_collection1,
                res[0].section1_image_collection2,
                res[0].section1_image_collection3,
                res[0].section1_image_collection4,
            ])
            setImageSlider2([
                res[0].section2_image_collection,
                res[0].section2_image_collection1,
                res[0].section2_image_collection2,
                res[0].section2_image_collection3,
                res[0].section2_image_collection4,
            ])
            setImageSlider3([
                res[0].section3_image_collection,
                res[0].section3_image_collection1,
                res[0].section3_image_collection2,
                res[0].section3_image_collection3,
                res[0].section3_image_collection4,
            ])
            setImageSlider4([
                res[0].section4_image_collection,
                res[0].section4_image_collection1,
                res[0].section4_image_collection2,
                res[0].section4_image_collection3,
                res[0].section4_image_collection4,
            ])
        }
        fetchData()
    }, [])

    const settings = {
        className: "center",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dotsClass: "slick-dots slick-thumb slider-banner-dot-container",
    }

    const settingsDots1 = {
        customPaging: function(i) {
            return (
                <div className="slider-banner-dot">
                    <img src={BASE_URL+imageslider1[i]} alt="."/>
                </div>
            );
        },
    }
    const settingsDots2 = {
        customPaging: function(i) {
            return (
                <div href="#" className="slider-banner-dot">
                    <img src={BASE_URL+imageslider2[i]} alt="."/>
                </div>
            );
        },
    }
    const settingsDots3 = {
        customPaging: function(i) {
            return (
                <div href="#" className="slider-banner-dot">
                    <img src={BASE_URL+imageslider3[i]} alt="."/>
                </div>
            );
        },
    }
    const settingsDots4 = {
        customPaging: function(i) {
            return (
                <div href="#" className="slider-banner-dot">
                    <img src={BASE_URL+imageslider4[i]}  alt="."/>
                </div>
            );
        },
    }
    let settingSlider1 = { ...settings,  ...settingsDots1 }
    let settingSlider2 = { ...settings,  ...settingsDots2 }
    let settingSlider3 = { ...settings,  ...settingsDots3 }
    let settingSlider4 = { ...settings,  ...settingsDots4 }
    return (
        <>
            <Helmet>
                <title>TDA Luxury Toys | Brand</title>
            </Helmet>
            <Container fluid className="brand-page-main">
                <Row>
                    <Col lg={12} xs={12} className="brand-banner">
                        {
                            response.banner_image ? 
                            <img src={BASE_URL+response.banner_image} alt="brand"/>
                            :
                            <img src={ImageBanner} alt="brand"/>
                        }
                    </Col>
                    <Col lg={12} xs={12}>
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={12} xs={12}>
                                    <p className="text-1 section" 
                                        dangerouslySetInnerHTML={{__html: 
                                                response.banner_description ?
                                                response.banner_description
                                                :
                                                ""
                                        }}
                                    >
                                    </p>
                                </Col>
                                <Col lg={5} xs={12} className="text-center">
                                    <a href=
                                    {
                                        response.banner_link_official ?
                                        response.banner_link_official
                                        :
                                        "#"
                                    }     
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.banner_link_official_title ?
                                            "button-rectangle button-brand-page"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                            {
                                                response.banner_link_official_title ?
                                                response.banner_link_official_title
                                                :
                                                ""
                                            }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                                <hr />
                                <Col lg={8} xs={12}>
                                    <p className="text-2 text-center text-italic section2"
                                        dangerouslySetInnerHTML={{__html: 
                                            response.banner_paragraph ?
                                            response.banner_paragraph
                                            :
                                            ""
                                        }}
                                    >
                                    </p>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col lg={8} xs={12} className="text-center section-slider-brand">
                                    <h1 className='text-head-1'>
                                        {
                                            response.section1_title ?
                                            response.section1_title
                                            :
                                            ""
                                        }
                                    </h1>
                                    <p className="text-1"
                                        dangerouslySetInnerHTML={{__html: 
                                            response.section1_paragraph ?
                                            response.section1_paragraph
                                            :
                                            ""
                                        }}
                                    >
                                    </p>
                                </Col>

                                <Col lg={7} xs={12} className="text-center">
                                    <Slider {...settingSlider1} 
                                    className="slider-brand-page">
                                        {
                                            response.section1_image_collection ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section1_image_collection} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section1_image_collection1 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section1_image_collection1} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section1_image_collection2 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section1_image_collection2} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section1_image_collection3 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section1_image_collection3} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section1_image_collection4 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section1_image_collection4} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                    </Slider>
                                </Col>
                                
                                <Col lg={6} xs={12} className="text-center">
                                    <a href={
                                        response.section1_text_link ?
                                        response.section1_text_link
                                        :
                                        "#"
                                    }
                                    
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.section1_text_link_title ?
                                            "button-rectangle button-brand-page"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                {
                                                    response.section1_text_link_title ?
                                                    response.section1_text_link_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                                <hr />
                            </Row>
                            
                            <Row className="justify-content-center">
                                <Col lg={8} xs={12} className="text-center section-slider-brand">
                                    <h1 className='text-head-1'>
                                        {
                                            response.section2_title ?
                                            response.section2_title
                                            :
                                            ""
                                        }
                                    </h1>
                                    <p className="text-1"
                                        dangerouslySetInnerHTML={{__html: 
                                            response.section2_paragraph ?
                                            response.section2_paragraph
                                            :
                                            ""
                                        }}
                                    >
                                        
                                    </p>
                                </Col>
                                <Col lg={7} xs={12} className="text-center">
                                    <Slider {...settingSlider2} 
                                    className="slider-brand-page">
                                        {
                                            response.section2_image_collection ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section2_image_collection} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section2_image_collection1 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section2_image_collection1} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section2_image_collection2 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section2_image_collection2} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section2_image_collection3 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section2_image_collection3} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section2_image_collection4 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section2_image_collection4} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                    </Slider>
                                </Col>
                                
                                <Col lg={6} xs={12} className="text-center display-none">
                                    <a href={
                                        response.section2_text_link ?
                                        response.section2_text_link
                                        :
                                        "#"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.section2_text_link_title ?
                                            "button-rectangle button-brand-page"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                {
                                                    response.section2_text_link_title ?
                                                    response.section2_text_link_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                                <hr />
                            </Row>
                            
                            <Row className="justify-content-center">
                                <Col lg={8} xs={12} className="text-center section-slider-brand">
                                    <h1 className='text-head-1'>
                                        {
                                            response.section3_title ?
                                            response.section3_title
                                            :
                                            ""
                                        }
                                    </h1>
                                    <p className="text-1"
                                        dangerouslySetInnerHTML={{__html: 
                                            response.section3_paragraph ?
                                            response.section3_paragraph
                                            :
                                            ""
                                        }}
                                    >
                                    </p>
                                </Col>

                                <Col lg={7} xs={12} className="text-center">
                                    <Slider {...settingSlider3} 
                                    className="slider-brand-page">
                                        {
                                            response.section3_image_collection ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section3_image_collection} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section3_image_collection1 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section3_image_collection1} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section3_image_collection2 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section3_image_collection2} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section3_image_collection3 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section3_image_collection3} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section3_image_collection4 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section3_image_collection4} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                    </Slider>
                                </Col>
                                
                                <Col lg={6} xs={12} className="text-center display-none">
                                    <a href={
                                        response.section3_text_link ?
                                        response.section3_text_link
                                        :
                                        "#"
                                        
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.section3_text_link_title ?
                                            "button-rectangle button-brand-page"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                href={
                                                    response.section3_text_link_title ?
                                                    response.section3_text_link_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                                <hr />
                            </Row>
                            
                            <Row className="justify-content-center">
                                <Col lg={8} xs={12} className="text-center section-slider-brand">
                                    <h1 className='text-head-1'>
                                        {
                                            response.section4_title ?
                                            response.section4_title
                                            :
                                            ""
                                        }
                                    </h1>
                                    <p className="text-1 text-italic"
                                        dangerouslySetInnerHTML={{__html: 
                                            response.section4_paragraph ?
                                            response.section4_paragraph
                                            :
                                            ""
                                        }}
                                    >
                                    </p>
                                </Col>

                                <Col lg={7} xs={12} className="text-center">
                                    <Slider {...settingSlider4} 
                                    className="slider-brand-page">
                                        {
                                            response.section4_image_collection ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section4_image_collection} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section4_image_collection1 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section4_image_collection1} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section4_image_collection2 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section4_image_collection2} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section4_image_collection3 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section4_image_collection3} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                        {
                                            response.section4_image_collection4 ? 
                                            <div className="slider-brand-page-container">
                                                <img src={BASE_URL+response.section4_image_collection4} alt="slider"/>
                                            </div>
                                            :
                                            ""
                                        }
                                    </Slider>
                                </Col>
                                
                                <Col lg={6} xs={12} className="text-center display-none">
                                    <a href={
                                        response.section4_text_link ?
                                        response.section4_text_link
                                        :
                                        "#"
                                        
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.section4_text_link_title ?
                                            "button-rectangle button-brand-page"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                {
                                                    response.section4_text_link_title ?
                                                    response.section4_text_link_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                            </Row>

                            
                            <Row className="justify-content-center">
                                <Col lg={12} xs={12} className="text-center">
                                    <a
                                        href={
                                            response.text_link_mono_gallery ?
                                            response.text_link_mono_gallery
                                            :
                                            "#"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.text_link_mono_gallery_title ?
                                            "button-rectangle button-rectangle-1 button-brand-page-footer-1"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                {
                                                    response.text_link_mono_gallery_title ?
                                                    response.text_link_mono_gallery_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                    <span className="brand-divider-button"> </span>
                                    <a href={
                                        response.text_link_mono_specification ?
                                        response.text_link_mono_specification
                                        :
                                        "#"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                        <button
                                        className={
                                            response.text_link_mono_specification_title ?
                                            "button-rectangle button-rectangle-2 button-brand-page-footer-2"
                                            :
                                            "display-none"
                                        }
                                        >
                                            <p>
                                                {
                                                    response.text_link_mono_specification_title ?
                                                    response.text_link_mono_specification_title
                                                    :
                                                    ""
                                                }
                                            </p>
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
